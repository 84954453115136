import request from '@/utils/request.js'

import { urlPath } from '@/services/apiBaseInfo.js'

export const queryCascader = (data) => {
  return request({
    methods: 'GET',
    url: urlPath + 'apiCascader',
    params: {
      ...data
    }
  })
}
