<template>
  <div class="api_base_info">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        接口管理
        <el-tooltip class="item" effect="dark" content="点击表格行内容跳转" placement="right">
          <i class="el-icon-info"></i>
        </el-tooltip>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <div slot="header" class="clearfix" style="overflow: hidden;">
        <!-- <span>卡片名称</span> -->
        <el-button type="primary" @click="handleAdd">新增接口</el-button>
      </div>
      <!-- 当前接口信息展示 -->
      <el-table
        :data="tableData"
        style="width: 100%">
        <el-table-column
          prop="id"
          label="接口编号"
          width="80">
        </el-table-column>
        <el-table-column
          prop="api_name"
          label="接口名称">
        </el-table-column>
        <el-table-column
          prop="api_method"
          label="请求方式">
        </el-table-column>
        <el-table-column
          prop="api_path"
          label="请求路径">
        </el-table-column>
        <el-table-column
          prop="project_id"
          label="所属项目">
        </el-table-column>
        <el-table-column
          prop="module_id"
          label="所属模块">
        </el-table-column>
        <el-table-column
          prop="description"
          label="接口描述">
        </el-table-column>
        <el-table-column
          prop="status"
          label="接口状态">
        </el-table-column>
        <el-table-column
          prop="created_time"
          label="创建时间">
        </el-table-column>
        <el-table-column
          label="操作"
          width="150">
          <template slot-scope="scope">
            <el-button round type="warning" @click.stop="handleEdit(scope.row)">编辑</el-button>
            <el-button round type="danger" @click.stop="handleDel(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

    </el-card>

    <!-- 测试接口分页 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageInfo.currentPage"
      :page-sizes="[1, 10, 30, 50]"
      :page-size="pageInfo.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pageInfo.total_count">
    </el-pagination>

    <el-dialog
      :title="isAdd?'新增接口':'编辑接口'"
      :visible.sync="dialogVisibleApiBase"
      width="50%"
      @close="closeDialogHandle">
      <el-form :model="ruleFormApiBase" :rules="rulesApiBase" ref="ruleFormRefApiBase" label-width="120px" class="demo-ruleForm">
        <el-form-item v-if="!isAdd" label="接口ID">
          <el-input v-model="ruleFormApiBase.id" disabled></el-input>
        </el-form-item>
        <el-form-item label="接口名称" prop="api_name">
          <el-input v-model="ruleFormApiBase.api_name"></el-input>
        </el-form-item>
        <el-form-item label="请求方式" prop="api_method">
          <el-select v-model="ruleFormApiBase.api_method" placeholder="请选择接口类型">
            <el-option label="GET" value="GET"></el-option>
            <el-option label="POST" value="POST"></el-option>
            <el-option label="PUT" value="PUT"></el-option>
            <el-option label="DELETE" value="DELETE"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="接口路径" prop="name">
          <el-input v-model="ruleFormApiBase.api_path" placeholder="/xxx/name/url"></el-input>
        </el-form-item>
        <el-form-item label="所属项目/模块" prop="name">
          <el-cascader
            v-model="moduleCascaderValue"
            :options="moduleCascaderOptions"
            :props="{ expandTrigger: 'hover', label: 'name', value: 'id'}"
            @change="moduleHandleChange"
            style="width: 100%;">
          </el-cascader>
        </el-form-item>
        <el-form-item label="接口状态" prop="delivery">
          <el-switch v-model="ruleFormApiBase.status" active-value="1" inactive-value="0"></el-switch>
        </el-form-item>
        <el-form-item label="接口描述" prop="desc">
          <el-input type="textarea" v-model="ruleFormApiBase.description"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleApiBase = false">取 消</el-button>
        <el-button v-if="isAdd" type="primary" @click="apiBaseAdd">新 增</el-button>
        <el-button v-if="!isAdd" type="primary" @click="apiBaseEdit">编 辑</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import { queryApiBaseInfoList, addApiBaseInfo, editApiBaseInfo, deleteApiBaseInfo } from '@/services/apiBaseInfo.js'
import { queryCascader } from '@/services/apiCascader.js'
export default {
  name: 'ApiBaseInfo',
  data () {
    return {
      isAdd: true,
      tableData: [{
        id: '1',
        api_name: '王小虎',
        description: '上海市普陀区金沙江路 1518 弄'
      }],
      pageInfo: {
        currentPage: 1,
        size: 10,
        total_count: null
      },
      formLabelWidth: '120px',
      dialogVisibleApiBase: false,
      ruleFormApiBase: {
        api_name: '',
        api_method: '',
        api_path: '',
        description: '',
        project_id: '',
        module_id: '',
        status: '1'
      },
      moduleCascaderValue: [],
      moduleCascaderOptions: [],
      rulesApiBase: {
        api_name: [
          { required: true, message: '请输入活动名称', trigger: 'blur' },
          { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        api_method: [
          { required: true, message: '请选择接口类型', trigger: 'change' }
        ],
        date1: [
          { type: 'date', required: true, message: '请选择日期', trigger: 'change' }
        ],
        date2: [
          { type: 'date', required: true, message: '请选择时间', trigger: 'change' }
        ],
        type: [
          { type: 'array', required: true, message: '请至少选择一个活动性质', trigger: 'change' }
        ],
        resource: [
          { required: true, message: '请选择活动资源', trigger: 'change' }
        ],
        desc: [
          { required: true, message: '请填写活动形式', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.loadapiBaseInfoList()
  },
  methods: {
    async loadapiBaseInfoList () {
      const queryObj = {
        query_type: 'query_list',
        page: this.pageInfo.currentPage,
        size: this.pageInfo.size
      }
      const { data: res } = await queryApiBaseInfoList(queryObj)
      if (res.status === 1) {
        this.tableData = res.data
        this.pageInfo.total_count = res.total_count
      }
    },
    // 模块级联信息
    async loadModuleCascaderData () {
      const queryObj = { cascader_type: 'module' }
      const { data: res } = await queryCascader(queryObj)
      if (res.status === 200) {
        this.moduleCascaderOptions = res.data
      }
    },
    // 接口信息 分页处理
    handleSizeChange (val) {
      this.pageInfo.size = val
      this.loadapiBaseInfoList()
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      this.pageInfo.currentPage = val
      this.loadapiBaseInfoList()
      console.log(`当前页: ${val}`)
    },
    moduleHandleChange () {
      this.ruleFormApiBase.project_id = this.moduleCascaderValue[0]
      this.ruleFormApiBase.module_id = this.moduleCascaderValue[1]
    },
    handleAdd () {
      this.isAdd = true
      this.ruleFormApiBase = {
        api_name: '',
        api_method: '',
        api_path: '',
        description: '',
        project_id: '',
        module_id: '',
        status: '1'
      }
      this.dialogVisibleApiBase = true
      this.loadModuleCascaderData()
    },
    handleEdit (rowData) {
      this.isAdd = false
      this.ruleFormApiBase = rowData
      this.loadModuleCascaderData()
      this.moduleCascaderValue = [rowData.project_id, rowData.module_id]
      this.dialogVisibleApiBase = true
    },
    handleDel (rowData) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const deleteObj = { id: rowData.id }
        const { data: res } = await deleteApiBaseInfo(deleteObj)
        if (res.status === 1) {
          this.$message.success('删除接口成功')
          this.loadapiBaseInfoList()
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      })
    },
    async apiBaseAdd () {
      const addApiObj = this.ruleFormApiBase
      addApiObj.api_operation = 'add'
      const { data: res } = await addApiBaseInfo(addApiObj)
      if (res.status === 1) {
        this.dialogVisibleApiBase = false
        this.$message.success('添加接口成功')
        this.loadapiBaseInfoList()
      }
    },
    async apiBaseEdit () {
      const editApiBaseObj = this.ruleFormApiBase
      editApiBaseObj.api_operation = 'edit'
      const { data: res } = await editApiBaseInfo(editApiBaseObj)
      if (res.status === 1) {
        this.dialogVisibleApiBase = false
        this.$message.success('编辑接口成功')
        this.loadapiBaseInfoList()
      }
    },
    closeDialogHandle () {
      this.ruleFormApiBase = {
        api_name: '',
        api_method: '',
        api_path: '',
        description: '',
        project_id: '',
        module_id: '',
        status: '1'
      }
      this.moduleCascaderValue = []
      this.$refs.ruleFormRefApiBase.resetFields()
      this.loadapiBaseInfoList()
    }
  }
}
</script>

<style>

</style>
